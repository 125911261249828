import React, { useState, useEffect} from 'react'
import { makeStyles, Dialog, DialogTitle, DialogContent, Button, Grid, createStyles, IconButton, TextField, Theme, CheckBox} from '@material-ui/core'
import TableComponent from '../components/TableComponent'
import FormModal from '../components/FormModal'
import {getStoragePricingList, postStoragePricing, deleteStoragePricing,updateStoragePricing } from '../services/catalogs/storagePricing'
import { getStorage } from '../services/catalogs/storageActions'
import { getClients } from '../services/catalogs/clientsActions'
import DialogConfirmation from '../components/DialogConfirmation'
import { useDispatch } from 'react-redux'
import { SET_BLUR_SCREEN } from '../UserContext/UserReducer'
import moment from 'moment'

const useStyles = makeStyles((theme) => 
createStyles({
    root: {
        '& > *': {
            margin: theme.spacing(1)
        }
    }
}))

const StoragePricing = () => {
  const [resultDetail, setResultDetail] = useState([])
  const [results, setResults] = useState([])
  const [totalRecords, setTotalRecords] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(25)
  const [showModal, setShowModal] = useState(false)
  const [detailsData, setDetailsData] = useState([])
  const [actionType, setActionType] = useState(0)
  const [statusFilter, setStatusFilter] = useState("")
  const [pageNumber, setPageNumber] = useState(1)
  const [pageBefore, setPageBefore] = useState(false)
  const [filtering, setFiltering] = useState(false)
  const [confirmOpen, setConfirmOpen] = useState(false)
  const [orderByState, setOrderBy] = useState()
  const [resultsTemp, setResultsTemp] = useState([])
  const [dataConfirmation, setDataConfirmation] = useState()
  const [clients, setClients] = useState([])
  const [storage, setStorage] = useState([])


  const classes = useStyles();
  const dispatch = useDispatch()

  useEffect(() => {
    // GET ENDPOINT DATA
    getData()
    getStorageData()
    getClientsData()
    
  }, [])

  const getData = async () => {
    dispatch(SET_BLUR_SCREEN({ 
      showBlurScreen: true,
    }))

    const result = await getStoragePricingList()
    if(result?.Data){
      result?.Data.forEach((e) => {
        e["Start"] = e["Start"].slice(0,10)
        e["Finish"] = e["Finish"].slice(0,10)
      })
    }
    setData(result)   

    dispatch(SET_BLUR_SCREEN({ 
      showBlurScreen: false,
    }))
  }

  const getStorageData = async () => {
    dispatch(SET_BLUR_SCREEN({ 
      showBlurScreen: true,
    }))

    const result = await getStorage(true)
    setStorage(result.Data)

    dispatch(SET_BLUR_SCREEN({ 
      showBlurScreen: false,
    }))
  }

  const getClientsData = async () => {
    dispatch(SET_BLUR_SCREEN({ 
      showBlurScreen: true,
    }))

    const result = await getClients(true)
    setClients(result.Data)

    dispatch(SET_BLUR_SCREEN({ 
      showBlurScreen: false,
    }))
  }


  const setData = (res) => {
    if(res !== null){
        if(res?.Data){
            setResults(res.Data)
            setResultsTemp(res.Data)
            setTotalRecords(res.Data.length)
        }
    } else {
        setResults([])
        setResultsTemp([])
        setTotalRecords(0)
    }
  }

  const handleCloseModal = () => {
    setShowModal(false)
    setActionType(0)
    getData()
  }

  const titleHeader = () => {
    switch(actionType){
      case 1:
        return "Create"
      case 2:
        return "Edit"
      default:
          return "Details"
    }
  }

  const setClose = () => {
    setShowModal(false)
    setActionType(0)
    getData()
  }

  const handleShowModal = () => {
    setShowModal(true)
  }

  const getOrd = (ord) => {
    setOrderBy(ord)
    orderBy(ord, resultsTemp)
  }

  const orderBy = (ord, data) => {
    if (ord !== undefined)
      return data.sort((a, b) => (a[ord.key] < b[ord.key] ? -1 : 1) * (ord.directioon === 'asc' ? 1 : -1))
  }

  const showResultHandle = (result) => {
    setResultDetail({ ...result })
    const detailsData = [
      {
        title: "Description",
        disabled: true,
      },
      {
        title: "Client Name",
        disabled: true,
      },
      {
        title: "Storage Name",
        disabled: true,
      },
      {
        title: "Start",
        disabled: true,
      },
      {
        title: "Finish",
        disabled: true,
      },
      {
        title: "TotalPrice",
        disabled: true,
      },
      {
        title: "Creation Date",
        disabled: true,
      },
      {
        title: "IsActive",
        type: "boolean",
        disabled: true,
      }
    ]
    setActionType(3)
    setDetailsData(detailsData)
    handleShowModal()
  }

  const editHandle = (result) => {
    setActionType(2)
    setResultDetail(result)
    detailsDataAssignation()
    handleShowModal()
  } 

  const handleDelete = async(id) => {
    dispatch(SET_BLUR_SCREEN({ 
      showBlurScreen: true,
    }))

    const result = await deleteStoragePricing(id)
    getData()

  }
  
  const sendAuthOp = () => {
    handleDelete(dataConfirmation)
    setDataConfirmation("")
    setConfirmOpen(false)
  }

  const handleDialogConfirmation =(data) => {
    setConfirmOpen(true)
    setDataConfirmation(data.id)
  }

  const detailsDataAssignation = (opType) => {
    const detailsData = [{
        title: "Description",
        key: "Description",
        type: "input"
      },{
      title: "Client",
      key: "Client",
      type: "dropdown",
      options: clients
    },
    {
      title: "Storage",
      key: "Storage",
      type: "dropdown",
      options: storage
    },
    {
        title: "Start",
        key: "Start",
        type: "datetime",
        textFieldProps: () => ({
          InputProps: {
            inputProps: {
              min: moment().format("YYYY-MM-DD[T]HH:mm:ss")
            }
          }
        })
    },
    {
        title: "Finish",
        key: "Finish",
        type: "datetime",
        textFieldProps: (values) => ({
          InputProps: {
            inputProps: {
              min: moment().format("YYYY-MM-DD[T]HH:mm")
            }
          },
          disabled: values.Start === "",
          value: moment(values.Start) >= moment(values.Finish) ? "" : values.Finish
        })
    },
    {
      title: "IsActive",
      key: "IsActive",
      type: "boolean"
    },
    ]
    setDetailsData(Object.assign(detailsData))
  }

  const createHandler = () => {
    setActionType(1)
    const newForm = {
      Client: "",
      Storage: "",
      Start: "",
      Finish: "",
      Description: ""
    } 
    setResultDetail(Object.assign(newForm))
    detailsDataAssignation(1)
    handleShowModal()
  }
  const renderFormModal = () => {
    return (
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        disableEnforceFocus
        open={showModal}
        onClose={handleCloseModal}
        aria-labelledby="dialog-report-bi"
        fullWidth={true}
        maxWidth={true}
      >
        <DialogTitle id="action-type" className="form-Dialog">
          <Grid
            justify="space-between"
            container
            sytle={{ alignItems: 'center' }}
          >
            <Grid item>
              {titleHeader()}
            </Grid>
            <Grid item>
              <IconButton onClick={handleCloseModal} style={{ flex: 1}}>
                <i class="fa-solid fa-x" style={{ color: "red"}}></i>
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>

        <DialogContent>
          {
            actionType !== 0 &&
            <FormModal 
              name="Mock"
              data={resultDetail}
              details={detailsData}
              postProcess={postStoragePricing}
              putProcess={updateStoragePricing}
              actionType={actionType}
              setClose={setClose}
            />
          }
        </DialogContent>
      </Dialog>
    )
  }

  return (
    <>
        <Grid container direction='column' style={{"marginLeft": "2%", "marginTop": "-7.5%"}}>
          <h1>Storage pricing</h1>
        </Grid>
        
            <div className='buttonCont'>
                <Button
                  startIcon={<i className='fa-solid fa-plus'/>}
                  variant="contained"
                  color="primary"
                  onClick={createHandler}
                >
                  Create new
                </Button>
              
          </div>
            <TableComponent 
                data={results}
                columns={[
                    {
                        title: 'Client Name',
                        type: 'string',
                        visible: true,
                        sortable: true,
                    },
                    {
                      title: 'Storage Name',
                      type: 'string',
                      visible: true,
                      sortable: true,
                    },
                    {
                        title: 'Start',
                        type: 'string',
                        visible: true,
                        sortable: true,
                    },
                    {
                        title: 'Finish',
                        type: 'string',
                        visible: true,
                        sortable: true,
                    },
                    {
                      title: 'IsActive',
                      type: 'boolean',
                      visible: true,
                      sortable: true,
                    },
                    {
                        title: '',
                        type: 'actions',
                        visible: true,
                    }
                ]}
                actions={[{
                    type: 'show-details',
                    func: showResultHandle,
                    className: 'btn-show',
                    paramNeeded: 'all',
                },{
                    type: 'edit',
                    func: editHandle,
                    className: 'btn-show',
                    paramNeeded: 'all',
                    editable: true,
                },
                {
                    type: 'delete',
                    func: handleDialogConfirmation,
                    className: 'btn-show',
                    paramNeeded: 'all',
                    editable: true,
                }]}
                getNext={() => {}}
                getRowsPerPage={() => {}}
                totalRecords={totalRecords}
                rowsPerPageData={rowsPerPage}
                pageBefore={pageBefore}
                orderByData={getOrd}
            />
            <DialogConfirmation
              title="Warning!"
              message="Are you sure you want to delete it?"
              showModal={confirmOpen}
              setShowModal={setConfirmOpen}
              onConfirm={sendAuthOp}
            />
        {showModal && renderFormModal()}
    </>
  )
}

export default StoragePricing