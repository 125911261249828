import React from 'react'
import { Backdrop, CircularProgress } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'

const Loader = () => {
  const showLoader = useSelector((state) => state.user.showBlurScreen)

  return (
    <Backdrop
    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
    open={showLoader}
    style={{ zIndex: '9000000'}}
    >
        <CircularProgress color="primary" thickness={5} size={80}/>
    </Backdrop>
  )
}

export default Loader