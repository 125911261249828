import axios from 'axios'
import { refreshToken } from '../services/catalogs/loginActions'

export const requestHandle = (req, headers) =>{
    headers?.forEach(val =>{
        req.headers[val.key] = val.value
    })
    return req
}


export const successHandler = (res) => res

export const integrationMiddleware = (url, auth) => {
    const token = localStorage.getItem("Auth_Token")
    const headers = {
        "Accept": '*/*',
        "Auth_Token": token,
        'Content-type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE',
        'Access-Control-Allow-Headers': 'Origin, Content-Type, Authorization, Accept, Content-Length'
    }
    const axiosInst = axios.create({
        baseURL: url,
        headers
    })

   
    return axiosInst
}
export default integrationMiddleware