import { integrationMiddleware } from "../network/middlewareHttp";
import { baseURL } from "./FetchUrl";

class ClientOrderDetails{
    apiURL = baseURL
    getEndpoint = '/api/ClientOrders/get'
    postEndpoint = '/api/ClientOrders/post'
    putEndpoint = '/api/ClientOrders/put'
    deleteEndpoint = '/api/ClientOrders/delete'
    http = integrationMiddleware(this.apiURL, 'AUTH TOKEN')
    
    getClientOrderDetails = async() => {
        return new Promise(async(resolve, reject) => {
            this.http.get(this.apiURL + this.getEndpoint)
            .then(res => {
                resolve(res)
            })
            .catch(error => {
                reject(error)
            })
        })
    }
    postClientOrderDetails = async(body) => {
        return new Promise(async(resolve, reject) => {
            this.http.post(this.apiURL + this.postEndpoint, body )
            .then(res => {
                resolve(res)
            })
            .catch(error => {
                reject(error)
            })
        })
    }

    deleteClientOrderDetails = async(id) => {
        return new Promise(async(resolve, reject) => {
            this.http.delete(this.apiURL + this.deleteEndpoint + '/' + id )
            .then(res => {
                resolve(res)
            })
            .catch(error => {
                reject(error)
            })
        })
    }

    updateClientOrderDetails = async(id, body) => {
        return new Promise(async(resolve, reject) => {
            this.http.put(this.apiURL + this.putEndpoint + '/' + id, body )
            .then(res => {
                resolve(res)
            })
            .catch(error => {
                reject(error)
            })
        })
    }

}

export default ClientOrderDetails
