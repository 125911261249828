import { integrationMiddleware } from "../network/middlewareHttp";
import { AxiosInstance } from "axios";
import { baseURL } from "./FetchUrl";

class ClientsCatalog{
    apiURL = baseURL
    getEndpoint = '/api/clients/get'
    postEndpoint = '/api/clients/post'
    putEndpoint = '/api/clients/put'
    deleteEndpoint = '/api/clients/delete'
    http = integrationMiddleware(this.apiURL, 'AUTH TOKEN')

    getClientsList = async(filter) => {
        return new Promise(async(resolve, reject) => {
            this.http.get(this.apiURL + this.getEndpoint + "?filter=" + (filter ? "true" : "false"))
            .then(res => {
                resolve(res)
            })
            .catch(error => {
                reject(error)
            })
        })
    }
    postClient = async(body) => {
        return new Promise(async(resolve, reject) => {
            this.http.post(this.apiURL + this.postEndpoint, body )
            .then(res => {
                resolve(res)
            })
            .catch(error => {
                reject(error)
            })
        })
    }

    deleteClient = async(id) => {
        return new Promise(async(resolve, reject) => {
            this.http.delete(this.apiURL + this.deleteEndpoint + '/' + id )
            .then(res => {
                resolve(res)
            })
            .catch(error => {
                reject(error)
            })
        })
    }

    updateClient = async(id, body) => {
        return new Promise(async(resolve, reject) => {
            this.http.put(this.apiURL + this.putEndpoint + '/' + id, body )
            .then(res => {
                resolve(res)
            })
            .catch(error => {
                reject(error)
            })
        })
    }

}

export default ClientsCatalog
