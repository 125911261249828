import StatusCatalog from "../status"

const service = new StatusCatalog()

export const getStatus = async (filter) => {
    const result = await service.getStatusList(filter)
    if(result.status === 200){
        return result.data
    }
    return result.data
}

export const postStatus = async (body) => {
    const result = await service.postStatus(body)
    if(result.status === 200){
        return result.data
    }
    return result.data
}

export const deleteStatus = async (id) => {
    const result = await service.deleteStatus(id)
    if(result.status === 200){
        return result.data
    }
    return result.data
}

export const updateStatus = async (body, id) => {
    const result = await service.updateStatus(body.id || body.Id, body)
    if(result.status === 200){
        return result.data
    }
    return result.data
}