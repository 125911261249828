import React from 'react'
import { Typography, IconButton, Divider, List, Drawer, Tooltip } from '@material-ui/core'
import { useTheme } from '@material-ui/core'
import NavigationListItem from './NavigationListItem'
import { routes } from '../screens/Routes'

const NavigationRoutes = () => {
    const theme = useTheme()

    const renderRoutesList = (routes) => {
        return routes?.map(r => {
            return (
                <NavigationListItem
                    route={r}
                />
            )
        })
    }
  return (
    <Drawer style={{"width": "100vh"}} variant="permanent" className='drawer drawerClose' classes="drawerClose">
        <List className='general-list' style={{"width": "100vh"}}>
            {renderRoutesList(routes)}
        </List>
    </Drawer>
  )
}

export default NavigationRoutes