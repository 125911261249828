import StorageDetails from "../storageDetails"

const service = new StorageDetails()

export const getStorageDetailsList = async () => {
    const result = await service.getStorageDetailsList()
    if(result.status === 200){
        return result.data
    }
    return result.data
}

export const postStorageDetails = async (body) => {
    const result = await service.postStorageDetails(body)
    if(result.status === 200){
        return result.data
    }
    return result.data
}

export const deleteStorageDetails = async (id) => {
    const result = await service.deleteStorageDetails(id)
    if(result.status === 200){
        return result.data
    }
    return result.data
}

export const updateStorageDetails = async (body, id) => {
    const result = await service.updateStorageDetails(body.id || body.Id, body)
    if(result.status === 200){
        return result.data
    }
    return result.data
}