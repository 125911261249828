import React from 'react'
import { ListItem, ListItemIcon, ListItemText, List } from '@material-ui/core'
import { useNavigate } from 'react-router-dom'
import IconItem from './IconItem'

const NavigationListItem = (route) => {
    const navigate = useNavigate()

    const isRouteActive = (path) => {
        // TRUE OR FALSE
    }

    const drawNavRoutes = () => {
        const isActive = isRouteActive() ? 'activeLink' : ''
        
        return (
            <>
                <ListItem button className={isActive} onClick={() => navigate(route.route.name)}>
                    <ListItemIcon>
                        <IconItem icon={route} />
                    </ListItemIcon>
                </ListItem>
            </>
        )
    }
  return drawNavRoutes()
}

export default NavigationListItem