import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { SET_USER_LOGGED, SET_BLUR_SCREEN, SET_USER_LOGGED_OUT } from '../../UserContext/UserReducer';
import { doLogin } from '../../services/catalogs/loginActions';

const theme = createTheme();

export default function SignIn() {
  
    const navigate = useNavigate()
    const dispatch = useDispatch()

  const [userMail, setUserMail] = useState("")
  const [userPassword, setUserPassword] = useState("")

  const handleSubmit = async (e) => {
    dispatch(SET_BLUR_SCREEN({ 
      showBlurScreen: true,
    }))

    e.preventDefault()
    const response = await doLogin({ email: userMail, password: userPassword})
    localStorage.setItem("Auth_Token", response?.accessToken)
    localStorage.setItem("Refresh_Token", response?.refreshToken)
    //  DO LOGIN
    dispatch(SET_USER_LOGGED({ // WE ONLY DO THE UPDATE ONCE THE LOGIN IS DONE CORRECTLY
        name: response?.email,
        authToken: response?.accessToken,
        refreshToken: response?.refreshToken,
        uid: response?.uid
    }))

    dispatch(SET_BLUR_SCREEN({ 
      showBlurScreen: true,
    }))
    
    navigate("/clients")
    
  };

 
  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography component="h1" variant="h5">
           Welcome
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={(e) => setUserMail(e.target.value)}
              value ={userMail}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={(e) => setUserPassword(e.target.value)}
              value ={userPassword}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}