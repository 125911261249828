import { integrationMiddleware } from "../network/middlewareHttp";
import { AxiosInstance } from "axios";
import { baseURL } from "./FetchUrl";

class LoginCatalog{
    apiURL = baseURL
    doLogin = '/api/login'
    revokeEndpoint = '/api/revoke'
    refreshEndpoint = '/api/refresh'
    
    http = integrationMiddleware(this.apiURL, 'AUTH TOKEN')

    loginUser = async(body) => {
        return new Promise(async(resolve, reject) => {
            this.http.post(this.apiURL + this.doLogin, body)
            .then(res => {
                resolve(res)
            })
            .catch(error => {
                reject(error)
            })
        })
    }
    revokeToken = async(body) => {
        return new Promise(async(resolve, reject) => {
            this.http.post(this.apiURL + this.revokeEndpoint, body )
            .then(res => {
                resolve(res)
            })
            .catch(error => {
                reject(error)
            })
        })
    }
    refreshToken = async(body) => {
        return new Promise(async(resolve, reject) => {
            this.http.post(this.apiURL + this.refreshEndpoint, {refresh_token: body} )
            .then(res => {
                resolve(res)
            })
            .catch(error => {
                reject(error)
            })
        })
    }

}

export default LoginCatalog
