import StorageCatalog from "../storage"

const service = new StorageCatalog()

export const getStorage = async (filter) => {
    const result = await service.getStorageList(filter)
    if(result.status === 200){
        return result.data
    }
    return result.data
}

export const postStorage = async (body) => {
    const result = await service.postStorage(body)
    if(result.status === 200){
        return result.data
    }
    return result.data
}

export const deleteStorage = async (id) => {
    const result = await service.deleteStorage(id)
    if(result.status === 200){
        return result.data
    }
    return result.data
}

export const updateStorage = async (body, id) => {
    const result = await service.updateStorage(body.id, body)
    if(result.status === 200){
        return result.data
    }
    return result.data
}