import React, { useState, useEffect} from 'react'
import { makeStyles, Dialog, DialogTitle, DialogContent, Button, Grid, createStyles, IconButton, TextField, Theme, CheckBox} from '@material-ui/core'
import TableComponent from '../components/TableComponent'
import FormModal from '../components/FormModal'
import { getClients, postClient, updateClient, deleteClient } from '../services/catalogs/clientsActions'
import DialogConfirmation from '../components/DialogConfirmation'
import { useDispatch } from 'react-redux'
import { SET_BLUR_SCREEN } from '../UserContext/UserReducer'

const useStyles = makeStyles((theme) => 
createStyles({
    root: {
        '& > *': {
            margin: theme.spacing(1)
        }
    }
}))

const ClientsCatalog = () => {
  const [resultDetail, setResultDetail] = useState([])
  const [results, setResults] = useState([])
  const [totalRecords, setTotalRecords] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(25)
  const [showModal, setShowModal] = useState(false)
  const [detailsData, setDetailsData] = useState([])
  const [actionType, setActionType] = useState(0)
  const [statusFilter, setStatusFilter] = useState("")
  const [pageNumber, setPageNumber] = useState(1)
  const [pageBefore, setPageBefore] = useState(false)
  const [filtering, setFiltering] = useState(false)
  const [confirmOpen, setConfirmOpen] = useState(false)
  const [orderByState, setOrderBy] = useState()
  const [resultsTemp, setResultsTemp] = useState([])
  const [dataConfirmation, setDataConfirmation] = useState()

  const classes = useStyles();
  const dispatch = useDispatch()

  useEffect(() => {
    // GET ENDPOINT DATA
    getData()
    
  }, [])

  const getData = async () => {
    dispatch(SET_BLUR_SCREEN({ 
      showBlurScreen: true,
    }))

    const result = await getClients()
    setData(result)   

    dispatch(SET_BLUR_SCREEN({ 
      showBlurScreen: false,
    }))
  }

  const setData = (res) => {
    if(res !== null){
        if(res?.Data){
            setResults(res.Data)
            setResultsTemp(res.Data)
            setTotalRecords(res.Data.length)
        }
    } else {
        setResults([])
        setResultsTemp([])
        setTotalRecords(0)
    }
  }

  const handleCloseModal = () => {
    setShowModal(false)
    setActionType(0)
    getData()
  }

  const titleHeader = () => {
    switch(actionType){
      case 1:
        return "Create"
      case 2:
        return "Edit"
      default:
          return "Details"
    }
  }

  const setClose = () => {
    setShowModal(false)
    setActionType(0)
    getData()
  }

  const handleShowModal = () => {
    setShowModal(true)
  }

  const getOrd = (ord) => {
    setOrderBy(ord)
    orderBy(ord, resultsTemp)
  }

  const orderBy = (ord, data) => {
    if (ord !== undefined)
      return data.sort((a, b) => (a[ord.key] < b[ord.key] ? -1 : 1) * (ord.directioon === 'asc' ? 1 : -1))
  }

  const showResultHandle = (result) => {
    setResultDetail({ ...result })
    const detailsData = [
      {
        title: "Name",
        disabled: true,
      },
      {
        title: "NIT",
        disabled: true,
      },
      {
        title: "Address",
        disabled: true,
      },
      {
        title: "Email",
        disabled: true,
      },
      {
        title: "IsActive",
        type: "boolean",
        disabled: true,
      }
    ]
    setActionType(3)
    setDetailsData(detailsData)
    handleShowModal()
  }

  const editHandle = (result) => {
    setActionType(2)
    setResultDetail(result)
    detailsDataAssignation()
    handleShowModal()
  } 

  const handleDelete = async(id) => {
    dispatch(SET_BLUR_SCREEN({ 
      showBlurScreen: true,
    }))

    const result = await deleteClient(id)
    getData()

  }
  
  const sendAuthOp = () => {
    handleDelete(dataConfirmation)
    setDataConfirmation("")
    setConfirmOpen(false)
  }

  const handleDialogConfirmation =(data) => {
    setConfirmOpen(true)
    setDataConfirmation(data.id)
  }

  const detailsDataAssignation = (opType) => {
    const detailsData = [{
      title: "Name",
      key: "Name",
      type: "input"
    },
    {
      title: "NIT",
      key: "NIT",
      type: "input"
    },
    {
      title: "Address",
      key: "Address",
      type: "input"
    },
    {
      title: "Email",
      key: "Email",
      type: "input"
    },
    {
      title: "IsActive",
      key: "IsActive",
      type: "boolean"
    },
    ]
    setDetailsData(Object.assign(detailsData))
  }

  const createHandler = () => {
    setActionType(1)
    const newForm = {
      Name: "",
      NIT: "",
      Address: "",
      Email: "",
    }
    setResultDetail(Object.assign(newForm))
    detailsDataAssignation(1)
    handleShowModal()
  }
  const renderFormModal = () => {
    return (
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        disableEnforceFocus
        open={showModal}
        onClose={handleCloseModal}
        aria-labelledby="dialog-report-bi"
        fullWidth={true}
        maxWidth={true}
      >
        <DialogTitle id="action-type" className="form-Dialog">
          <Grid
            justify="space-between"
            container
            sytle={{ alignItems: 'center' }}
          >
            <Grid item>
              {titleHeader()}
            </Grid>
            <Grid item>
              <IconButton onClick={handleCloseModal} style={{ flex: 1}}>
                <i class="fa-solid fa-x" style={{ color: "red"}}></i>
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>

        <DialogContent>
          {
            actionType !== 0 &&
            <FormModal 
              name="Mock"
              data={resultDetail}
              details={detailsData}
              postProcess={postClient}
              putProcess={updateClient}
              actionType={actionType}
              setClose={setClose}
            />
          }
        </DialogContent>
      </Dialog>
    )
  }

  return (
    <>
        <Grid container direction='column' style={{"marginLeft": "2%", "marginTop": "-7.5%"}}>
          <h1>Clients</h1>
        </Grid>
        
            <div className='buttonCont'>
                <Button
                  startIcon={<i className='fa-solid fa-plus'/>}
                  variant="contained"
                  color="primary"
                  onClick={createHandler}
                >
                  Create new
                </Button>
              
          </div>
            <TableComponent 
                data={results}
                columns={[
                    {
                        title: 'Name',
                        type: 'string',
                        visible: true,
                        sortable: true,
                    },
                    {
                      title: 'NIT',
                      type: 'string',
                      visible: true,
                      sortable: true,
                    },
                    {
                      title: 'Address',
                      type: 'string',
                      visible: true,
                      sortable: true,
                    },
                    {
                      title: 'Email',
                      type: 'string',
                      visible: true,
                      sortable: true,
                    },
                    {
                      title: 'IsActive',
                      type: 'boolean',
                      visible: true,
                      sortable: true,
                    },
                    {
                        title: '',
                        type: 'actions',
                        visible: true,
                    }
                ]}
                actions={[{
                    type: 'show-details',
                    func: showResultHandle,
                    className: 'btn-show',
                    paramNeeded: 'all',
                },{
                    type: 'edit',
                    func: editHandle,
                    className: 'btn-show',
                    paramNeeded: 'all',
                    editable: true,
                },
                {
                    type: 'delete',
                    func: handleDialogConfirmation,
                    className: 'btn-show',
                    paramNeeded: 'all',
                    editable: true,
                }]}
                getNext={() => {}}
                getRowsPerPage={() => {}}
                totalRecords={totalRecords}
                rowsPerPageData={rowsPerPage}
                pageBefore={pageBefore}
                orderByData={getOrd}
            />
            <DialogConfirmation
              title="Warning!"
              message="Are you sure you want to delete it?"
              showModal={confirmOpen}
              setShowModal={setConfirmOpen}
              onConfirm={sendAuthOp}
            />
        {showModal && renderFormModal()}
    </>
  )
}

export default ClientsCatalog