/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'

// eslint-disable-next-line import/prefer-default-export
export const userLoggedSlice = createSlice({
  name: 'user',
  authToken: 'token',
  refreshToken: 'token',
  sessionToken: 'token',
  showBlurScreen: 'blur',
  setAlert: false,
  uid: null,
  initialState: {
    name: null,
    authToken: null,
    refreshToken: null,
    sessionToken: null,
    isActive: false,
    setAlert: false,
    uid: null
  },
  reducers: {
    SET_USER_LOGGED: (state, action) => {
      state.name = action.payload.name
      state.authToken = action.payload.authToken
      state.refreshToken = action.payload.refreshToken
      state.showBlurScreen = undefined
      state.uid = action.payload.uid
    },
    SET_USER_LOGGED_OUT: (state) => {
      state.name = null
      state.authToken = null
      state.refreshToken = null
      state.showBlurScreen = false
      state.sessionToken = null
    },
    SET_BLUR_SCREEN: (state, action) => {
      state.showBlurScreen = action.payload.showBlurScreen
    },
    SET_ALERT: (state, action) => {
      state.showBlurScreen = action.payload.showBlurScreen
    },
  },
})

export const {
  SET_USER_LOGGED,
  SET_USER_LOGGED_OUT,
  SET_BLUR_SCREEN,
} = userLoggedSlice.actions
export const loggedUser = (state) => state.user

/* In order to get the data from the current user you ought to
import useSelector and loggedUser. Once done the previous assign as per shown below:

let data = useSelector(loggedUser)

You will get both the username and authToken as an object

*/

export default userLoggedSlice.reducer
