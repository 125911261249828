import React, { useState, useEffect} from 'react'
import { makeStyles, Dialog, DialogTitle, DialogContent, Button, Grid, createStyles, IconButton, TextField, Theme, CheckBox} from '@material-ui/core'
import TableComponent from '../components/TableComponent'
import FormModal from '../components/FormModal'
import { getClients } from '../services/catalogs/clientsActions'
import { getProductsList } from '../services/catalogs/productsActions'
import { getStatus } from '../services/catalogs/statusActions'
import DialogConfirmation from '../components/DialogConfirmation'
import { useDispatch } from 'react-redux'
import { SET_BLUR_SCREEN } from '../UserContext/UserReducer'
import moment from 'moment'
import { getClientOrderDetails, postClientOrderDetails, deleteClientOrderDetails, updateClientOrderDetails } from '../services/catalogs/clientOrderDetails'

const useStyles = makeStyles((theme) => 
createStyles({
    root: {
        '& > *': {
            margin: theme.spacing(1)
        }
    }
}))

const ClientsOrders = () => {
  const [resultDetail, setResultDetail] = useState([])
  const [results, setResults] = useState([])
  const [totalRecords, setTotalRecords] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(25)
  const [showModal, setShowModal] = useState(false)
  const [detailsData, setDetailsData] = useState([])
  const [actionType, setActionType] = useState(0)
  const [statusFilter, setStatusFilter] = useState("")
  const [pageNumber, setPageNumber] = useState(1)
  const [pageBefore, setPageBefore] = useState(false)
  const [filtering, setFiltering] = useState(false)
  const [confirmOpen, setConfirmOpen] = useState(false)
  const [orderByState, setOrderBy] = useState()
  const [resultsTemp, setResultsTemp] = useState([])
  const [dataConfirmation, setDataConfirmation] = useState()

  // STATE
  const [clientsList, setClientsList] = useState([])
  const [productsList, setProductsList] = useState([])
  const [statusList, setStatusList] = useState([])

  const [cart, setCart] = useState([])


  const classes = useStyles();
  const dispatch = useDispatch()

  useEffect(() => {
    // GET ENDPOINT DATA
    getData()
    getStatusList()
    getClientsList()
    getProductsListFunction()
    
  }, [])

  const getData = async () => {
    const response = await getClientOrderDetails()
    setData(response)
  }

  const setCheckedProducts = (id, quantity) => {
    const isInList = cart?.findIndex((obj) => obj.Product === parseInt(id))
    if (isInList > -1) {
        let items = [...cart];
        let item = {...cart[isInList]};
        item.Quantity = parseInt(quantity);
        items[isInList] = item;
        setCart(items);
    } else {
        setCart([...cart, {
          Product: parseInt(id),
          Quantity: parseInt(quantity)
        }])
    }
  }

  const getCurrenQuantity = (id) => {
    const obj = cart?.find((obj) => obj?.id === id)
    if (obj) return obj?.quantity
    return undefined
  }

  const getClientsList = async () => {
    dispatch(SET_BLUR_SCREEN({ 
        showBlurScreen: true,
      }))
  
      const result = await getClients(true)
      setClientsList(result.Data)   
  
      dispatch(SET_BLUR_SCREEN({ 
        showBlurScreen: false,
      }))
    }

    const getStatusList = async () => {
      dispatch(SET_BLUR_SCREEN({ 
          showBlurScreen: true,
        }))
    
        const result = await getStatus(true)
        setStatusList(result.Data)   
    
        dispatch(SET_BLUR_SCREEN({ 
          showBlurScreen: false,
        }))
      }

    const getProductsListFunction = async () => {
        dispatch(SET_BLUR_SCREEN({ 
            showBlurScreen: true,
          }))
      
          const result = await getProductsList(true)
          setProductsList(result.Data)   
      
          dispatch(SET_BLUR_SCREEN({ 
            showBlurScreen: false,
          }))
        }
  const setData = (res) => {
    if(res !== null){
        if(res?.Data){
            setResults(res.Data)
            setResultsTemp(res.Data)
            setTotalRecords(res.Data.length)
        }
    } else {
        setResults([])
        setResultsTemp([])
        setTotalRecords(0)
    }
  }

  const handleCloseModal = () => {
    setShowModal(false)
    setActionType(0)
    getData()
  }

  const titleHeader = () => {
    switch(actionType){
      case 1:
        return "Create"
      case 2:
        return "Edit"
      default:
          return "Details"
    }
  }

  const setClose = () => {
    setShowModal(false)
    setActionType(0)
    getData()
  }

  const handleShowModal = () => {
    setShowModal(true)
  }

  const getOrd = (ord) => {
    setOrderBy(ord)
    orderBy(ord, resultsTemp)
  }

  const orderBy = (ord, data) => {
    if (ord !== undefined)
      return data.sort((a, b) => (a[ord.key] < b[ord.key] ? -1 : 1) * (ord.directioon === 'asc' ? 1 : -1))
  }

  const showResultHandle = (result) => {
    setResultDetail({ ...result })
    const detailsData = [
      {
        title: "Description",
        disabled: true,
      },
      {
        title: "Client Name",
        disabled: true,
      },
      {
        title: "Products",
        key: "Products",
        type: "data-visualization"
      },
      {
        title: "IsActive",
        key: "IsActive",
        type: "boolean",
        disabled: true,
      }
    ]
    setActionType(3)
    setDetailsData(detailsData)
    handleShowModal()
  }

  const editHandle = (result) => {
    setActionType(2)
    setResultDetail(result)
    detailsDataAssignation(-1)
    handleShowModal()
  } 

  const handleDelete = async(id) => {
    dispatch(SET_BLUR_SCREEN({ 
      showBlurScreen: true,
    }))

   const result = await deleteClientOrderDetails(id)
    getData()

  }
  
  const sendAuthOp = () => {
    handleDelete(dataConfirmation)
    setDataConfirmation("")
    setConfirmOpen(false)
  }

  const handleDialogConfirmation =(data) => {
    setConfirmOpen(true)
    setDataConfirmation(data.id)
  }

  const detailsDataAssignation = (opType) => {
    console.log(opType)
    let detailsData = []
    if(opType === -1) {
      detailsData = [{
        title: "Description",
        key: "Description",
        type: "input"
      },
      {
        title: "Status",
        key: "Status",
        type: "dropdown",
        options: statusList
      },
      {
        title: "EndDate",
        key: "EndDate",
        type: "datetime",
        textFieldProps: () => ({
          InputProps: {
            inputProps: {
              min: moment().format("YYYY-MM-DD[T]HH:mm:ss")
            }
          }
        })
      },
      {
          title: "Client",
          key: "Client",
          type: "dropdown",
          options: clientsList
        }
      ]
    } else {
      detailsData = [{
        title: "Description",
        key: "Description",
        type: "input"
      },
      {
        title: "EndDate",
        key: "EndDate",
        type: "datetime",
        textFieldProps: () => ({
          InputProps: {
            inputProps: {
              min: moment().format("YYYY-MM-DD[T]HH:mm:ss")
            }
          }
        })
      },
      {
          title: "Client",
          key: "Client",
          type: "dropdown",
          options: clientsList
        },
        {
          title: "Status",
          key: "Status",
          type: "dropdown",
          options: statusList
        },
      {
        title: "Products",
        key: "Products",
        type: "numeric-selection-array",
        options: productsList
      },
      ]
    }
    
    setDetailsData(Object.assign(detailsData))
  }

  const createHandler = () => {
    setActionType(1)
    const newForm = {
      Description: "",
      Status: "",
      EndDate: "",
      Client: "",
      Products: ""
    }
    setResultDetail(Object.assign(newForm))
    detailsDataAssignation(1)
    handleShowModal()
  }
  const renderFormModal = () => {
    return (
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        disableEnforceFocus
        open={showModal}
        onClose={handleCloseModal}
        aria-labelledby="dialog-report-bi"
        fullWidth={true}
        maxWidth={true}
      >
        <DialogTitle id="action-type" className="form-Dialog">
          <Grid
            justify="space-between"
            container
            sytle={{ alignItems: 'center' }}
          >
            <Grid item>
              {titleHeader()}
            </Grid>
            <Grid item>
              <IconButton onClick={handleCloseModal} style={{ flex: 1}}>
                <i class="fa-solid fa-x" style={{ color: "red"}}></i>
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>

        <DialogContent>
          {
            actionType !== 0 &&
            <FormModal 
              name="Mock"
              data={resultDetail}
              details={detailsData}
              setCheckedProducts = {setCheckedProducts}
              cart={cart}
              getCurrenQuantity={getCurrenQuantity}
              postProcess={postClientOrderDetails}
              putProcess={updateClientOrderDetails}
              actionType={actionType}
              setClose={setClose}
            />
          }
        </DialogContent>
      </Dialog>
    )
  }

  return (
    <>
        <Grid container direction='column' style={{"marginLeft": "2%", "marginTop": "-7.5%"}}>
          <h1>Clients Orders</h1>
        </Grid>
        
            <div className='buttonCont'>
                <Button
                  startIcon={<i className='fa-solid fa-plus'/>}
                  variant="contained"
                  color="primary"
                  onClick={createHandler}
                >
                  Create new
                </Button>
              
          </div>
            <TableComponent 
                data={results}
                columns={[
                    {
                        title: 'Description',
                        type: 'string',
                        visible: true,
                        sortable: true,
                    },
                    {
                        title: 'Client Name',
                        type: 'string',
                        visible: true,
                        sortable: true,
                    },
                    {
                      title: 'Status Name',
                      type: 'string',
                      visible: true,
                      sortable: true,
                    },
                    {
                      title: 'CreationDate',
                      type: 'datetime',
                      visible: true,
                      sortable: true,
                    },
                    {
                      title: 'EndDate',
                      type: 'datetime',
                      visible: true,
                      sortable: true,
                    },
                    {
                      title: 'IsActive',
                      type: 'boolean',
                      visible: true,
                      sortable: true,
                    },
                    {
                        title: '',
                        type: 'actions',
                        visible: true,
                    }
                ]}
                actions={[{
                    type: 'show-details',
                    func: showResultHandle,
                    className: 'btn-show',
                    paramNeeded: 'all',
                },{
                    type: 'edit',
                    func: editHandle,
                    className: 'btn-show',
                    paramNeeded: 'all',
                    editable: true,
                },
                {
                    type: 'delete',
                    func: handleDialogConfirmation,
                    className: 'btn-show',
                    paramNeeded: 'all',
                    editable: true,
                }]}
                getNext={() => {}}
                getRowsPerPage={() => {}}
                totalRecords={totalRecords}
                rowsPerPageData={rowsPerPage}
                pageBefore={pageBefore}
                orderByData={getOrd}
            />
            <DialogConfirmation
              title="Warning!"
              message="Are you sure you want to delete it?"
              showModal={confirmOpen}
              setShowModal={setConfirmOpen}
              onConfirm={sendAuthOp}
            />
        {showModal && renderFormModal()}
    </>
  )
}

export default ClientsOrders