import React, {useState} from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { SET_USER_LOGGED_OUT } from '../../UserContext/UserReducer'
import { Toolbar, AppBar, IconButton, Typography, Grid, List, ListItem, Tooltip } from '@material-ui/core'

import DialogConfirmation from '../DialogConfirmation'

const Header = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const mail = useSelector((state) => state.user.name)


  const handleLogOut = () =>{
    localStorage.clear()
    dispatch(SET_USER_LOGGED_OUT())
    navigate("/login")
  }

  const [open, setOpen] = useState(false)
  return (
    <AppBar elevation={0} position="fixed" className='navBar appBarLocked' >
        <Toolbar>
            <Grid container>
                <Grid item xs={2} md={1} className="column-logo">
                    
                </Grid>
                <Grid item xs={6} md={5} className="tittle">
                    <Typography variant='h6' className='d-none d-sm-block ml-2' noWrap>
                        SIGI 
                    </Typography>
                </Grid>
                <Grid item xs={4} md={6} className="columns-info">
                   <ListItem>
                    <Typography className='userName' noWrap>
                        { mail } 
                    </Typography>
                   </ListItem>

                   <ListItem>
                        <Tooltip title="Log out">
                            <IconButton onClick={() => setOpen(true)} className='exitIcon'>
                                <i className="fa-solid fa-right-from-bracket" />
                            </IconButton>
                        </Tooltip>
                   </ListItem>
                </Grid>
            </Grid>
        </Toolbar>

        <DialogConfirmation
            title="Logout"
            message="You want to logout?"
            showModal={open}
            setShowModal={setOpen}
            onConfirm={handleLogOut}
       />
    </AppBar>
  )
}

export default Header