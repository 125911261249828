import React from "react";

const IconItem = ({ icon}) => {
    return (
        <div style={{ "marginLeft": "10px"}}>
            <i className={`${icon.route.iconName}`} style={{ color: "black" }} />
            <p style={{ "margin": 0, "marginLeft": -15, "fontSize": "10px"}}>{icon.route.title}</p>
        </div>
    )
}

export default IconItem