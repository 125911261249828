import ProductsCatalog from "../products"
const service = new ProductsCatalog()

export const getProductsList = async (filter) => {
    const result = await service.getProductsList(filter)
    if(result.status === 200){
        return result.data
    }
    return result.data
}

export const postProducts = async (body) => {
    const result = await service.postProducts(body)
    if(result.status === 200){
        return result.data
    }
    return result.data
}

export const deleteProducts = async (id) => {
    const result = await service.deleteProducts(id)
    if(result.status === 200){
        return result.data
    }
    return result.data
}

export const updateProducts = async (body, id) => {
    const result = await service.updateProducts(body.id || body.Id, body)
    if(result.status === 200){
        return result.data
    }
    return result.data
}