import { integrationMiddleware } from "../network/middlewareHttp";
import { baseURL } from "./FetchUrl";

class StoragePricing{
    apiURL = baseURL
    getEndpoint = '/api/StoragePricing/get'
    postEndpoint = '/api/StoragePricing/post'
    putEndpoint = '/api/StoragePricing/put'
    deleteEndpoint = '/api/StoragePricing/delete'
    http = integrationMiddleware(this.apiURL, 'AUTH TOKEN')
    
    getStoragePricingList = async() => {
        return new Promise(async(resolve, reject) => {
            this.http.get(this.apiURL + this.getEndpoint)
            .then(res => {
                resolve(res)
            })
            .catch(error => {
                reject(error)
            })
        })
    }
    postStoragePricing= async(body) => {
        return new Promise(async(resolve, reject) => {
            this.http.post(this.apiURL + this.postEndpoint, body )
            .then(res => {
                resolve(res)
            })
            .catch(error => {
                reject(error)
            })
        })
    }

    deleteStoragePricing = async(id) => {
        return new Promise(async(resolve, reject) => {
            this.http.delete(this.apiURL + this.deleteEndpoint + '/' + id )
            .then(res => {
                resolve(res)
            })
            .catch(error => {
                reject(error)
            })
        })
    }

    updateStoragePricing = async(id, body) => {
        return new Promise(async(resolve, reject) => {
            this.http.put(this.apiURL + this.putEndpoint + '/' + id, body )
            .then(res => {
                resolve(res)
            })
            .catch(error => {
                reject(error)
            })
        })
    }

}

export default StoragePricing
