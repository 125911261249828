import ClientOrderDetails from "../ClientOrderDetails"

const service = new ClientOrderDetails()

export const getClientOrderDetails = async () => {
    const result = await service.getClientOrderDetails()
    if(result.status === 200){
        return result.data
    }
    return result.data
}

export const postClientOrderDetails = async (body) => {
    const result = await service.postClientOrderDetails(body)
    if(result.status === 200){
        return result.data
    }
    return result.data
}

export const deleteClientOrderDetails = async (id) => {
    const result = await service.deleteClientOrderDetails(id)
    if(result.status === 200){
        return result.data
    }
    return result.data
}

export const updateClientOrderDetails = async (body, id) => {
    const result = await service.updateClientOrderDetails(body.id, body)
    if(result.status === 200){
        return result.data
    }
    return result.data
}