import LoginCatalog from "../login"

const service = new LoginCatalog()

export const doLogin = async (body) => {
    console.log(body)
    const result = await service.loginUser(body)
    if(result.status === 200){
        return result.data
    }
    return result.data
}

export const revokeToken = async (body) => {
    const result = await service.revokeToken(body)
    if(result.status === 200){
        return result.data
    }
    return result.data
}

export const refreshToken = async (token) => {
    const result = await service.refreshToken(token)
    if(result.status === 200){
        return result.data
    }
    return result.data
}