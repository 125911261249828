import SupplierOrderDetails from "../SupplierOrderDetails"

const service = new SupplierOrderDetails()

export const getSupplierOrderDetails = async () => {
    const result = await service.getSupplierOrderDetails()
    if(result.status === 200){
        return result.data
    }
    return result.data
}

export const postSupplierOrderDetails = async (body) => {
    const result = await service.postSupplierOrderDetails(body)
    if(result.status === 200){
        return result.data
    }
    return result.data
}

export const deleteSupplierOrderDetails = async (id) => {
    const result = await service.deleteSupplierOrderDetails(id)
    if(result.status === 200){
        return result.data
    }
    return result.data
}

export const updateSupplierOrderDetails = async (body, id) => {
    const result = await service.updateSupplierOrderDetails(body.id, body)
    if(result.status === 200){
        return result.data
    }
    return result.data
}