import React, { useState, useEffect } from 'react'
import TablePaginationActions, { TablePaginationActionsProps } from '@material-ui/core/TablePagination/TablePaginationActions'
import { 
    Paper, TableContainer, Box, Table, TableHead, 
    TableRow, TableCell, TableBody, TablePagination, 
    IconButton, useTheme, TableSortLabel, Typography, 
    Tooltip, TextField
} from "@material-ui/core"
import moment from 'moment'
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons'

// import moment from 'moment'

const TableComponent = ({
    data,
    columns,
    getNext,
    getRowsPerPage,
    totalRecords = 1,
    filtering,
    rowsPerPageData = 1,
    hasPagination = true,
    actions,
    orderByData,
    ShowLoading,
    pageBefore
}) => {
  const [rowsPerPage, setRowsPerPage] = useState(0)
  const [page, setPage] = useState(0)
  const [lastPageCalled, setLastPageCalled] = useState(0)
  const [orderBy, setOrderBy] = useState({ key: '', directioon: 'desc'})

  useEffect(() => {
    setRowsPerPage(rowsPerPageData)
  }, [])

  const PaginateData = (pagProps) => {
    const th = useTheme();
    const { count, page, rowsPerPage, onChange } = pagProps;
    
    const handleBackClick = (e) => {
        onChange(e, page - 1)
    }

    const handleNextClick = async (e) => {
        const nextPage = page + 1;
        if (nextPage > lastPageCalled) {
            await getNext(nextPage + 1)
            setLastPageCalled(nextPage)
        }
        onChange(e, nextPage)
    }

    return (
        <div className='ml-2 d-flex'>
            <IconButton onClick={handleBackClick} disables={page === 0}>
                {
                    th.directioon === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />
                }
            </IconButton>
            <IconButton onClick={handleNextClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page" data-testid= 'btn-next' >
                {
                    th.directioon === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />
                }
            </IconButton>
        </div>
    )
  }

  const handlePageChange = (e, newPage) => setPage(newPage)

  const handleChangeRowsPerPage = async (e) => {
    const newRows = parseInt(e.target.value, 10);
    setRowsPerPage(newRows);
    setPage(0);
    setLastPageCalled(0);
    await getRowsPerPage(0);
  }
  
  const renderIconAction = (type, color, isEditable, title, icon) => {
    switch(type) {
        case 'show-details': 
            return <i className='fa-solid fa-eye' style={{ color: "black" }} />
        case 'edit': 
            return <i class="fa-solid fa-pencil" style={{ color: "orange" }} />
        case 'delete': 
            return <i className='fa-solid fa-trash-can' style={{ color: 'red' }} />
        default: 
            return <i className='fad fa-check-square' style={{ color }} />
    }
  }

  const returnParam = (element, param) => {
    if (!param) {
        return element['id'];
    } else if( param === "all") {
        return element;
    } else return element[param];
  }
 
  const rearrangeData = async (col) => {
    const key = col.key || col.title
    let direction = 'asc'
    if(orderBy && orderBy.key === key){
        direction = orderBy.directioon === 'asc' ? 'desc' : direction
    }
    setOrderBy({ key, directioon: direction})

    await orderByData({ key, directioon: direction})
  }

  const renderSortColumn = (col) => {
    const key = col.key || col.title;
    return (
        <TableSortLabel active={ orderBy.key === key} direction={orderBy.directioon} onClick={() => rearrangeData(col)}>
            { col.label || col.title }
        </TableSortLabel>
    )
  }

  const renderHeader = (col) => {
    const key = col.key || col.type === 'actions' ? col.title : col.key;
    return (
        col.visible === undefined || col.visible ? 
            <TableCell key={key} className={col.className} style={col.style}>
                {
                    col.sortable ? renderSortColumn(col) : (col.label || col.title)
                }
            </TableCell>
        : ''
    )
  }

  const renderCell = (element, col, i, index) => {
    if (col.visible || col.visible === undefined) {
        const key = col.key || col.title
        switch(col.type) {
            case 'number':
                    return (
                        <TableCell key={`${key}-${i}`} >
                            {
                                element[key]
                            }
                        </TableCell>
                    )
            case 'date':
                    return (
                        <TableCell key={`${key}-${i}`} >
                            {
                                // moment.unix(element[key]).format("YYYY-MM-DD")
                            }
                        </TableCell>
                    )
            case 'datetime':
                    return (
                        <TableCell key={`${key}-${i}`} >
                            {
                                moment(element[key]).format("YYYY-MM-DD hh:mma")
                            }
                        </TableCell>
                    )
            case 'boolean':
                    return (
                        <TableCell key={`${key}-${i}`} >
                            {
                                element[key] ? 'Yes' : 'No'
                            }
                        </TableCell>
                    )
            case 'actions':
                return(
                    <TableCell  key={`${key}-${i}`} className={`table-actions ${col.className || ''}`}>
                        {
                            actions && actions.map((action) => {
                                return <IconButton disabled={action.editable === undefined ? false : !action.editable} key={`button-${action.type}`} className={action.className} onClick={() => action.func(returnParam(element, action.paramNeeded), index)}>
                                    {
                                        renderIconAction(action.type, action.color, action.editable === undefined ? true : action.editable, action.title, action.icon)
                                    }
                                </IconButton>
                            })
                        }
                    </TableCell>
                )
            default:
                return (
                    <TableCell key={`${key}-${i}`} >
                        {
                            element[key]
                        }
                    </TableCell>
                )
        }
    } else return null
  }

  return (
    <Paper>
        {
            data.length > 0 ? 
            <>
                <TableContainer component={Box}>
                    <Table size='small' stickyHeader>
                        <TableHead>
                            <TableRow>
                                {
                                    columns.map((c) => renderHeader(c))
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((el, i) => (
                                    <TableRow>
                                        {
                                            columns.map((c, index) => renderCell(el, c, index, i ))
                                        }
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                {
                    hasPagination &&
                    <TablePagination
                    data-testid='pag'
                    component="div"
                    rowsPerPageOptions={[ 5, 10, 25, 50, 100, 200]}
                    count={totalRecords}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                        inputProps: { 'aria-label': 'rows per page'},
                        native: true,
                    }}
                    onChangePage={handlePageChange}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                    labelRowsPerPage="Filas por página"
                    labelDisplayedRows={
                        ({from, to, count}) => `${from} - ${to === -1 ? count : to} de ${count !== -1 ? count : to}`
                    }
                    />
                }
            </>
            : ShowLoading ? '' : <Typography component="h6" variant="h6" className="py-3 px-2">No available data</Typography>
        }
    </Paper>
  )
}

export default TableComponent