export const routes = [
    {
        iconName: "fa-solid fa-house",
        name: "clients",
        title: "Clients"
    },
    {
        iconName: "fa-solid fa-dollar-sign",
        name: "suppliers",
        title: "Suppliers"
    },
    {
        iconName: "fa-solid fa-shop",
        name: "storage",
        title: "Storage"
    },
    {
        iconName: "fa-solid fa-check",
        name: "category",
        title: "Categories"
    },
    {
        iconName: "fa-solid fa-question",
        name: "status",
        title: "Status"
    },
    {
        iconName: "fa-solid fa-boxes-stacked",
        name: "products",
        title: "Products"
    },
    {
        iconName: "fa-solid fa-money-bill-trend-up",
        name: "storage/pricing",
        title: "Booking"
    },
    {
        iconName: "fa-solid fa-truck",
        name: "storage/details",
        title: "Storage details"
    },
    {
        iconName: "fa-solid fa-arrow-up-from-bracket",
        name: "clients/orders",
        title: "Clients Orders"
    },
    {
        iconName: "fa-solid fa-arrows-down-to-line",
        name: "suppliers/orders",
        title: "Suppliers Orders"
    }
]