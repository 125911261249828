import SuppliersCatalog from "../suppliers"

const service = new SuppliersCatalog()

export const getSuppliers = async () => {
    const result = await service.getSuppliersList()
    if(result.status === 200){
        return result.data
    }
    return result.data
}

export const postSupplier = async (body) => {
    const result = await service.postSupplier(body)
    if(result.status === 200){
        return result.data
    }
    return result.data
}

export const deleteSupplier = async (id) => {
    const result = await service.deleteSupplier(id)
    if(result.status === 200){
        return result.data
    }
    return result.data
}

export const updateSupplier = async (body, id) => {
    const result = await service.updateSupplier(body.id, body)
    if(result.status === 200){
        return result.data
    }
    return result.data
}