import React, { useState, useEffect} from 'react'
import { 
  Paper, Table, Typography, Button, select, TextField, 
  TableRow, TableContainer, TableCell, TableBody, Checkbox, 
  DialogContent, DialogTitle, Dialog, MenuItem, FormControl, Grid,
  IconButton, Tooltip, List, ListItemIcon, ListItem, ListItemText, Select
} from "@material-ui/core"
import { makeStyles, createStyles } from "@material-ui/styles";
import { useDispatch } from 'react-redux'
import { SET_BLUR_SCREEN } from '../UserContext/UserReducer';

const useModalStyles = makeStyles((theme) => ({
  root: {
      margin: 'auto',
      padding: '10px',
  },
  input: {
      border: '1px solid black',
      borderRadius: '4px',
      '&&&& label': {
          color: 'black'
      },
      '&&&& textarea': {
          color: 'black'
      },
  },
  warningButton: {
      backgroundColor: '#ba000d',
      color: 'white'
  },

  border: {
      border: 'none',
  },
  button: {
      maxWidth: 'fit-content'
  }
}))

const FormModal = ({
  data, details, actionType, postProcess, putProcess, setClose, setAlert,
  children, setCheckedProducts,cart
}) => {
  const classes = useModalStyles()
  const dispatch = useDispatch()

  const [newcreate, setNewCreate] = useState(Object.assign({}, data))  
  const [showModal, setShowModal] = useState(false)
  const [isHtmlModal, setIsHtmlModal] = useState(false)
  const [title, setTitle] = useState('')
  const [text, setText] = useState('')
  const [disabledForm, setDisabledForm] = useState('')


  const handlePostSubmit = async (e) => {

    dispatch(SET_BLUR_SCREEN({ 
      showBlurScreen: true,
    }))
    
    e.preventDefault()
    if(disabledForm === '') {
      if(cart) newcreate["Products"] = cart
      const request = await postProcess(newcreate)
      if(request.statusCode === 200){
        alert("Transaction completed")
      }else alert("An error ocurred during the transaction")
      setClose()
    }

    dispatch(SET_BLUR_SCREEN({ 
      showBlurScreen: false,
    }))
  }

  const handlePutSubmit = async (e) => {

    dispatch(SET_BLUR_SCREEN({ 
      showBlurScreen: true,
    }))

    e.preventDefault()
    if(disabledForm === '') {
      const request = await putProcess(newcreate)
      if(request.statusCode === 200){
        alert("Transaction completed")
      }else alert("An error ocurred during the transaction")
      setClose()
    }
    dispatch(SET_BLUR_SCREEN({ 
      showBlurScreen: false,
    }))
  }

  const handleChangeCheckBox = (e, value) => {
    const newdata = newcreate
    newdata[e.target.id] = value
    setNewCreate(newdata)
  }

  const handleChangeNumber = (e) => {
    const key = e.target.id
    const value = e.target.value
    const newValue = value.replace(/\D+/g, '')
    const newdata = Object.assign({}, newcreate)
    newdata[key] = newValue === '' ? '' : parseInt(newValue)
    setNewCreate(newdata)
  }

  const handleChange = (e, valueManual, keyManual) => {
    const key = e.target.id !== undefined ? e.target.id : e.target.name
    const value = e.target.value || valueManual
    const newdata = Object.assign({}, newcreate)
    Object.keys(newdata).map((k, id) => {
      if (k === key || k === keyManual) {
        newdata[key] = value
      }
    })
    setNewCreate(newdata)
  }


  const handleShowModal = (title, text, showHTMLModal = false) => {
    setTitle(title)
    setText(text)
    setIsHtmlModal(showHTMLModal)
    setShowModal(true)
  }

  const handleCloseModal = () => {
    setShowModal(false)
  }

  const renderBoxModal = () => {
    return (
      <Dialog onClose={handleShowModal} open={showModal} maxWidth="lg" fullWidth={true}>
        <DialogTitle>
          <Grid
            justify='space-between'
            container
            style={{ alignItem: 'center'}}
          >
            <Grid item>
              { title }
            </Grid>

            <Grid item>
              <IconButton onClick={handleCloseModal} style={{flex: 1}}>
                <i className='fad fa-times-circle fa-md' style={{ color: 'red'}}></i>
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          {
            text
          }
        </DialogContent>
      </Dialog>
    )
  }

  const returnElementToRender = (element, needToRender) => {
    if (needToRender === 'all'){
      return element
    } else {
      return element[needToRender]
    }
  }

  const handleChangeOption = (e, value, key) => {
    const newdata = Object.assign({}, newcreate)
    value === null ? newdata[key] = "" : newdata[key] = value.id
    setNewCreate(newdata)
  }

  const renderCells = (element, column, i) => {
    if(column.visible === undefined || column.visible){
      const key = column.key || column.title
      switch(column.type){
        case 'dropdown': 
          return(
            <TableRow key={key} hover role="checkbox">
              <TableCell className='border border-white'>
                <h3>{column.label || column.title}</h3>
              </TableCell>

              <TableCell className="border border-white">
                <FormControl>
                  <Select
                    id={column.title}
                    key={column.id}
                    name={column.title}
                    onChange={handleChange}
                    displayEmpty
                    value={element[key] ?? ''}
                    inputProps={{ "aria-label" : "select"}}
                  >
                    {
                      Object.values(column.options || []).map((option) => {
                        return(
                          <MenuItem id={column.title || column.id} key={option.id || option.name} value={option.id}>
                            {
                              option.name || option.Name
                            }
                          </MenuItem>
                        )
                      })
                    }
                  </Select>
                </FormControl>
              </TableCell>
            </TableRow>
          )
        case 'title': 
          let title = ''
          return(
              <TableRow key={key} hover role="checkbox">
              <TableCell className='border border-white'>
                <h3>{column.label || column.title}</h3>
              </TableCell>

              <TableCell className="border border-white">
                {
                  Object.values(column.options || []).forEach((option) => {
                    if (option.id === element[key]){
                      title = option.name
                    }
                  })
                }
              </TableCell>
            </TableRow>
          )
        case 'number':
          const value = column.default
          return(
            <TableRow key={key} hover role="checkbox">
            <TableCell className='border border-white'>
              <h3>{column.label || column.title}</h3>
            </TableCell>

            <TableCell className="border border-white">
              <TextField 
                variant="outlined"
                margin="dense"
                key={key}
                disabled={column.disabled}
                id={column.title}
                required
                type="number"
                value = {element[key] || 0}
                InputProps={{ inputProps: {min: 0}}}
                InputLabelProps={{ shrink: true}}
                onChange={(e) => handleChangeNumber(e)}
              />
            </TableCell>
          </TableRow>
        )
        case 'inputNumber':
          return(
            <TableRow key={key} hover role="checkbox">
            <TableCell className='border border-white'>
              <h3>{column.label || column.title}</h3>
            </TableCell>

            <TableCell className="border border-white">
              <TextField 
                variant="outlined"
                margin="dense"
                key={key}
                id={column.title}
                required
                disabled={column.disabled}
                type="number"
                value = {element[key] === null ? '' : element[key]}
                InputProps={{ inputProps: {min: 1}}}
                onChange={(e) => handleChangeNumber(e)}
              />
            </TableCell>
          </TableRow>
        )
        case 'input':
          return(
            <TableRow key={key} hover role="checkbox">
            <TableCell className='border border-white'>
              <h3>{column.label || column.title}</h3>
            </TableCell>

            <TableCell className="border border-white">
              <TextField 
                variant="outlined"
                margin="dense"
                key={key}
                id={column.title}
                required
                disabled={column.disabled}
                type="text"
                value = {element[key] || ''}
                InputProps={{ inputProps: {min: 1}}}
                onChange={(e) => handleChange(e)}
              />
            </TableCell>
          </TableRow>
        )
        case 'datetime':
          return(
            <TableRow key={key} hover role="checkbox">
            <TableCell className='border border-white'>
              <h3>{column.label || column.title}</h3>
            </TableCell>

            <TableCell className="border border-white">
              <TextField 
                variant="outlined"
                margin="dense"
                className={actionType === 3 ? classes.input : ''}
                key={key}
                id={column.title}
                required
                disabled={column.disabled}
                type="date"
                value = {element[key] || ''}
                InputProps={{ inputProps: {min: 1}}}
                onChange={(e) => handleChange(e)} 
                {...((column.textFieldProps && column.textFieldProps(element, setNewCreate)) || {})}
              />
            </TableCell>
          </TableRow>
        )
        case 'input-optional':
          return(
            <TableRow key={key} hover role="checkbox">
            <TableCell className='border border-white'>
              <h3>{column.label || column.title}</h3>
            </TableCell>

            <TableCell className="border border-white">
              <TextField 
                variant="outlined"
                margin="dense"
                key={key}
                id={column.title}
                type="text"
                disabled={column.disabled}
                value = {element[key] || ''}
                InputProps={{ inputProps: {min: 1}}}
                onChange={(e) => handleChange(e)}
              />
            </TableCell>
          </TableRow>
        )
        case 'boolean':
          return(
            <TableRow key={key} hover role="checkbox">
            <TableCell className='border border-white'>
              <h3>{column.label || column.title}</h3>
            </TableCell>

            <TableCell className="border border-white">
              <Checkbox 
                id={column.title}
                key={element[key]}
                name={key}
                onChange={(e, value) => {
                 handleChangeCheckBox(e, value)
                 column.onChangeCheck && column.onChangeCheck(value, element, setNewCreate)
                }}
                defaultValue={element[key]}
                defaultChecked={element[key]}
                disabled={column.disabled}
                inputProps = {{ "aria-label": 'checkboc'}}
                {...((column.textFieldProps && column.textFieldProps(element, setNewCreate)) || {})}
              />
            </TableCell>
          </TableRow>
        )
        case 'numeric-selection-array':
          return(
            <TableRow key={key} hover role="checkbox">
            <List dense component='div' role='list'>
              <TableCell className='border border-white'>
                  <h3>Add products to order</h3>
              </TableCell>
              {
                column?.options.map((el, index) => {
                 return(
                  <ListItem key={index}>
                    <Grid container direction="row">
                      <ListItemText primary={el.Name}/>
                      <TextField 
                      variant="outlined"
                      margin="dense"
                      key={key}
                      disabled={column.disabled}
                      id={el.id}
                      required
                      type="number"
                      value = {cart?.find((obj) => obj.Product === el.id)?.Quantity || 0}
                      InputProps={{ inputProps: {min: 0}}}
                      InputLabelProps={{ shrink: true}}
                      onChange={(e) => setCheckedProducts(e.target.id, e.target.value)}
                    />
                    </Grid>
                  </ListItem>
                 )
                })
              }
             </List>
           </TableRow>
          )
        case 'data-visualization':
          return(
            <TableRow key={key} hover>
               <TableCell className='border border-white'>
                  <h3>{column.label || column.title}</h3>
               </TableCell>

               <TableCell className="border border-white">
                {
                  element?.Products.map((element, index) =>  {
                    return(
                      <ListItem>
                        <Grid container direction="row">
                          <ListItemText primary={element.ProductName}/>
                          <TextField 
                          variant="outlined"
                          margin="dense"
                          key={key}
                          disabled={true}
                          id={element.ProductId}
                          required
                          type="number"
                          value = {element?.Quantity || 0}
                          InputProps={{ inputProps: {min: 0}}}
                          InputLabelProps={{ shrink: true}}
                        />
                        </Grid>
                      </ListItem>
                     )
                  })
                }
               </TableCell>
            </TableRow>
          )
        default:
          return(
            <TableRow key={key} hover role="checkbox">
            <TableCell className='border border-white'>
              <h3>{column.label || column.title}</h3>
            </TableCell>

            <TableCell className="border border-white">
              <TextField 
                className={classes.input}
                variant="outlined"
                margin="dense"
                key={key}
                id={column.title}
                type="text"
                disabled={column.disabled}
                value = {element[key] || ''}
                InputProps={{ inputProps: {min: 1}}}
                onChange={(e) => handleChange(e)}
              />
            </TableCell>
          </TableRow>
        )
      }
    } else {
      return null
    }
  }

  return (
    <Paper className="p-4">
      {
        data ? 
          <>
            <form onSubmit={actionType === 1 ? handlePostSubmit : handlePutSubmit} >
              <TableContainer>
                <Table>
                  <TableBody>
                    {
                      details.map((column, index) => {
                        return renderCells(newcreate, column, index)
                      })
                    }
                  </TableBody>
                </Table>
              </TableContainer>
              {
                actionType !== 3 && 
                  <div className='buttonCont'>
                      <Button
                        className="align-items-end"
                        type="submit"
                        color="primary"
                        variant="contained"
                        data-testid="btn-submit"
                      >
                        ok
                      </Button>
                  </div>  
              }
            </form>
            {renderBoxModal()}
          </>
          :
          <Typography component="h6" variant="h6" className='py-3 px-2'>
            No hay registros
          </Typography>
      }
      {
        children
      }
    </Paper>
  )
}

export default FormModal