import Header from './components/layout/Header';
import { useSelector, useDispatch } from 'react-redux'
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import NavigationRoutes from './components/NavigationRoutes';
import SignIn from './screens/Login';
import ClientsCatalog from './screens/ClientsCatalog';
import SuppliersCatalog from './screens/SuppliersCatalog';
import CategoriesCatalog from './screens/CategoriesCatalog';
import StorageCatalog from './screens/StorageCatalog';
import StatusCatalog from './screens/Status';
import ProductsCatalog from './screens/Products';
import StorageDetails from './screens/StorageDetails';
import StoragePricing from './screens/StoragePricing';
import Loader from './components/Loader';
import ClientsOrders from './screens/ClientsOrders';
import SuppliersOrders from './screens/SuppliersOrders';
import './App.scss';

function App() {

  const showNavbar = useSelector((state) => state.user.authToken)

  return (
      <main className='content'>
       
            <Router>
                  <>
                    {
                      showNavbar ? 
                      (
                        <>
                          <Loader />
                          <Header />
                          <NavigationRoutes />
                          <Routes>
                            <Route
                              path='/clients'
                            element = {<ClientsCatalog />}
                            />
                            <Route
                              path='/suppliers'
                            element = {<SuppliersCatalog />}
                            />
                             <Route
                              path='/category'
                            element = {<CategoriesCatalog />}
                            />
                             <Route
                              path='/storage'
                            element = {<StorageCatalog />}
                            />
                             <Route
                            path='*'
                            element = {<Navigate to="/clients" />}

                            />
                            <Route
                              path='/status'
                            element = {<StatusCatalog />}
                            />
                             <Route
                              path='/products'
                            element = {<ProductsCatalog />}
                            />
                            <Route
                              path='/storage/details'
                            element = {<StorageDetails />}
                            />
                             <Route
                              path='/storage/pricing'
                            element = {<StoragePricing />}
                            />
                            <Route
                              path='/clients/orders'
                            element = {<ClientsOrders />}
                            />
                            <Route
                              path='/suppliers/orders'
                            element = {<SuppliersOrders />}
                            />
                          </Routes>
                        </>
                      ) : ''
                    }
                    {
                      !showNavbar ? 
                        ( <Routes>
                          <Route
                            path='/login'
                          element = {<SignIn />}
                          />
                           <Route
                            path='*'
                            element = {<Navigate to="/login" />}
                          />
                          </Routes>
                      ) : ''
                    }
                  </>
            </Router>
      </main>
  );
}

export default App;
