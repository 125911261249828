import React from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Icon} from "@material-ui/core"

const DialogConfirmation = ({
  title,
  message,
  children,
  showModal,
  setShowModal,
  onConfirm,
  onCancel
}) => {
  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      disableEnforceFocus
      open={showModal}
      onClose={() => setShowModal(false)}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {message}
        {children}
      </DialogContent>
      <DialogActions>
        <IconButton onClick={() => onConfirm()}>
        <i class="fa-solid fa-check" style={{ color: "green"}}></i>
        </IconButton>
        <IconButton onClick={() => {
          setShowModal(false)
          onCancel && onCancel()
        }}>
          <i class="fa-solid fa-x" style={{ color: "red"}}></i>
        </IconButton>
      </DialogActions>
    </Dialog>
  )
}

export default DialogConfirmation