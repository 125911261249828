import ClientsCatalog from "../clients"

const service = new ClientsCatalog()

export const getClients = async (filter) => {
    const result = await service.getClientsList(filter)
    if(result.status === 200){
        return result.data
    }
    return result.data
}

export const postClient = async (body) => {
    const result = await service.postClient(body)
    if(result.status === 200){
        return result.data
    }
    return result.data
}

export const deleteClient = async (id) => {
    const result = await service.deleteClient(id)
    if(result.status === 200){
        return result.data
    }
    return result.data
}

export const updateClient = async (body, id) => {
    const result = await service.updateClient(body.id, body)
    if(result.status === 200){
        return result.data
    }
    return result.data
}