import StoragePricing from "../storagePricing"

const service = new StoragePricing()

export const getStoragePricingList = async () => {
    const result = await service.getStoragePricingList()
    if(result.status === 200){
        return result.data
    }
    return result.data
}

export const postStoragePricing = async (body) => {
    const result = await service.postStoragePricing(body)
    if(result.status === 200){
        return result.data
    }
    return result.data
}

export const deleteStoragePricing = async (id) => {
    const result = await service.deleteStoragePricing(id)
    if(result.status === 200){
        return result.data
    }
    return result.data
}

export const updateStoragePricing = async (body, id) => {
    const result = await service.updateStoragePricing(body.id || body.Id, body)
    if(result.status === 200){
        return result.data
    }
    return result.data
}