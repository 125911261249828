import CategoriesCatalog  from "../categories"

const service = new CategoriesCatalog()

export const getCategories = async (filter) => {
    const result = await service.getCategoriesList(filter)
    if(result.status === 200){
        return result.data
    }
    return result.data
}

export const postCategory = async (body) => {
    const result = await service.postCategori(body)
    if(result.status === 200){
        return result.data
    }
    return result.data
}

export const deleteCategory = async (id) => {
    const result = await service.deleteCategorie(id)
    if(result.status === 200){
        return result.data
    }
    return result.data
}

export const updateCategory = async (body, id) => {
    const result = await service.updateCategorie(body.id, body)
    if(result.status === 200){
        return result.data
    }
    return result.data
}